import JpgImgOne from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_01.jpg"
import JpgImgOne2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_01@1_5x.jpg"
import JpgImgOneMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_01@mob.jpg"
import JpgImgOneMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_01@mob1_5x.jpg"

import JpgImgTwo from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_02.jpg"
import JpgImgTwo2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_02@1_5x.jpg"
import JpgImgTwoMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_02@mob.jpg"
import JpgImgTwoMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_02@mob1_5x.jpg"

import JpgImgThree from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_03.jpg"
import JpgImgThree2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_03@1_5x.jpg"
import JpgImgThreeMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_03@mob.jpg"
import JpgImgThreeMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_03@mob1_5x.jpg"

import JpgImgFour from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_04.jpg"
import JpgImgFour2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_04@1_5x.jpg"
import JpgImgFourMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_04@mob.jpg"
import JpgImgFourMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_04@mob1_5x.jpg"

import JpgImgFive from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_05.jpg"
import JpgImgFive2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_05@1_5x.jpg"
import JpgImgFiveMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_05@mob.jpg"
import JpgImgFiveMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_05@mob1_5x.jpg"

import JpgImgSix from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_06.jpg"
import JpgImgSix2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_06@1_5x.jpg"
import JpgImgSixMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_06@mob.jpg"
import JpgImgSixMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_06@mob1_5x.jpg"

import JpgImgSeven from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_07.jpg"
import JpgImgSeven2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_07@1_5x.jpg"
import JpgImgSevenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_07@mob.jpg"
import JpgImgSevenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_07@mob1_5x.jpg"

import JpgImgEight from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_08.jpg"
import JpgImgEight2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_08@1_5x.jpg"
import JpgImgEightMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_08@mob.jpg"
import JpgImgEightMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_08@mob1_5x.jpg"

import JpgImgNine from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_09.jpg"
import JpgImgNine2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_09@1_5x.jpg"
import JpgImgNineMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_09@mob.jpg"
import JpgImgNineMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_09@mob1_5x.jpg"

import JpgImgTen from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_10.jpg"
import JpgImgTen2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_10@1_5x.jpg"
import JpgImgTenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_10@mob.jpg"
import JpgImgTenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_10@mob1_5x.jpg"

import JpgImgEleven from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_11.jpg"
import JpgImgEleven2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_11@1_5x.jpg"
import JpgImgElevenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_11@mob.jpg"
import JpgImgElevenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_11@mob1_5x.jpg"

import JpgImgTwelve from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_12.jpg"
import JpgImgTwelve2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_12@1_5x.jpg"
import JpgImgTwelveMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_12@mob.jpg"
import JpgImgTwelveMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_12@mob1_5x.jpg"

import JpgImgThirteen from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_13.jpg"
import JpgImgThirteen2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_13@1_5x.jpg"
import JpgImgThirteenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_13@mob.jpg"
import JpgImgThirteenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_13@mob1_5x.jpg"

import JpgImgFourteen from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_14.jpg"
import JpgImgFourteen2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_14@1_5x.jpg"
import JpgImgFourteenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_14@mob.jpg"
import JpgImgFourteenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_14@mob1_5x.jpg"

import JpgImgFifteen from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_15.jpg"
import JpgImgFifteen2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_15@1_5x.jpg"
import JpgImgFifteenMob from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_15@mob.jpg"
import JpgImgFifteenMob2x from "../../../../assets/images/raster/pages/cn/withoutvpn/SectionOne/cn-withoutvpn_15@mob1_5x.jpg"

const PG_CW_SECTION_ONE_CONTENT = {
  image: [
    [JpgImgOne, JpgImgOne2x, JpgImgOneMob, JpgImgOneMob2x],
    [JpgImgTwo, JpgImgTwo2x, JpgImgTwoMob, JpgImgTwoMob2x],
    [JpgImgThree, JpgImgThree2x, JpgImgThreeMob, JpgImgThreeMob2x],
    [JpgImgFour, JpgImgFour2x, JpgImgFourMob, JpgImgFourMob2x],
    [JpgImgFive, JpgImgFive2x, JpgImgFiveMob, JpgImgFiveMob2x],
    [JpgImgSix, JpgImgSix2x, JpgImgSixMob, JpgImgSixMob2x],
    [JpgImgSeven, JpgImgSeven2x, JpgImgSevenMob, JpgImgSevenMob2x],
    [JpgImgEight, JpgImgEight2x, JpgImgEightMob, JpgImgEightMob2x],
    [JpgImgNine, JpgImgNine2x, JpgImgNineMob, JpgImgNineMob2x],
    [JpgImgTen, JpgImgTen2x, JpgImgTenMob, JpgImgTenMob2x],
    [JpgImgEleven, JpgImgEleven2x, JpgImgElevenMob, JpgImgElevenMob2x],
    [JpgImgTwelve, JpgImgTwelve2x, JpgImgTwelveMob, JpgImgTwelveMob2x],
    [JpgImgThirteen, JpgImgThirteen2x, JpgImgThirteenMob, JpgImgThirteenMob2x],
    [JpgImgFourteen, JpgImgFourteen2x, JpgImgFourteenMob, JpgImgFourteenMob2x],
    [JpgImgFifteen, JpgImgFifteen2x, JpgImgFifteenMob, JpgImgFifteenMob2x],
  ],
}
export default PG_CW_SECTION_ONE_CONTENT
