import React from "react";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import Layout from "../../layouts/LayoutCn";
import Seo from "../../components/Seo";
import SectionOne from "../../components/cn/withoutvpn/SectionOne";

const PgCW = () => {
  return (
    <Layout bgHeader="#fff">
      <Seo
        title="如何在不使用VPN的情况下安装或更新AMZ侦探 PRO插件。"
        description="在中国如何通过VPN使用AMZ侦探工具"
        page="cn/withoutvpn"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <SectionOne />
    </Layout>
  );
};
export default PgCW;
