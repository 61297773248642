import React from "react";
import PG_CW_SECTION_ONE_CONTENT from "./index.content";
import TrackedLink from "../../../Analytics/TrackedLink";
import SectionOneScreenshot from "./SectionOneScreenshot";
import "./index.scss";

const SectionOne = () => {
  const { image } = PG_CW_SECTION_ONE_CONTENT;

  return (
    <main className="PgCW-SectionOne">
      <div className="PgCW-SectionOne__container">
        <div className="PgCW-SectionOne__block PgCW-SectionOne__blockOne ">
          <p>如何在不使用VPN的情况下安装或更新AMZ侦探 PRO插件。</p>
          <p>
            如果您无法访问谷歌Chrome浏览器网络商店下载AMZ侦探插件，请使用以下两种方法之一安装扩展。
          </p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>方法1-在微软Edge浏览器中安装扩展</b>
          </p>
          <p>
            1. 安装微软Edge浏览器或将此浏览器更新至最新版本（如果设备上已安装）
          </p>
          <p>
            2. 使用微软Edge浏览器，按照
            <TrackedLink
              class="PgCW-SectionOne__linkProvided"
              category="CNlandingWithoutVpn"
              action="DownloadExtension"
              path="https://microsoftedge.microsoft.com/addons/detail/amazon-product-finder-a/idcpecldgbbabloifgjogemdifohldkd"
              target
            >
              提供的链接
            </TrackedLink>
            从官方扩展商店（Microsoft Edge Add-ons）安装AMZ侦探 PRO扩展
          </p>
          <p>3.安装完成后，您就可以使用AMZ侦探PRO扩展，而无需任何其他设置</p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>方法2-在谷歌Chrome浏览器中安装扩展档案</b>
          </p>
          <p>请确认您使用最新版本的谷歌浏览器</p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>第一步，点击官网以下载安装包。</b>
          </p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>步驟2下载完成后请将安装包档案解压缩，详细方式请参考以下步骤：</b>
          </p>
          <p>1. 解压缩已下载的安装包</p>
          <SectionOneScreenshot
            image={image[0]}
            number="One"
            width={280}
            height={80}
          />
        </div>
      </div>
      <div className="PgCW-SectionOne__download">
        <div className="PgCW-SectionOne__yellowLine" />
        <div className="PgCW-SectionOne__container">
          <div className="PgCW-SectionOne__block PgCW-SectionOne__blockBtn">
            <TrackedLink
              class="PgCW-SectionOne__btnDownloadExtension"
              target
              category="CNlandingWithoutVpn"
              action="DownloadExtension"
              path="/extfiles/amzscout-pro.zip"
            >
              点击下载
            </TrackedLink>
          </div>
        </div>
      </div>
      <div className="PgCW-SectionOne__container">
        <div className="PgCW-SectionOne__block PgCW-SectionOne__blockTwo">
          <p>
            2. 打开谷歌浏览器，点击右上方菜单中的” 更多工具&gt;扩展程序” 将”
            开发者模式” 打开
          </p>
          <SectionOneScreenshot
            image={image[1]}
            number="Two"
            width={280}
            height={154}
          />
          <SectionOneScreenshot
            image={image[2]}
            number="Three"
            width={280}
            height={125}
          />
          <p>3. 点击” 加载已解压的扩展程序”</p>
          <SectionOneScreenshot
            image={image[3]}
            number="Four"
            width={280}
            height={135}
          />
          <p>4. 点击” 选择文件夹”</p>
          <SectionOneScreenshot
            image={image[4]}
            number="Five"
            width={280}
            height={130}
          />
          <p>5. 完成安装</p>
          <SectionOneScreenshot
            image={image[5]}
            number="Six"
            width={280}
            height={130}
          />
          <p>6.在谷歌浏览器右上方将会出现已经安装好的插件, 点击即可使用</p>
          <SectionOneScreenshot
            image={image[6]}
            number="Seven"
            width={280}
            height={180}
          />
          <div className="PgCW-SectionOne__grayLine" />
          <p>如果AMZ侦探插件有新的版本，请更新您的插件</p>
          <p>请确认您使用最新版本的谷歌浏览器</p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>
              步驟1首先至谷歌浏览器点击”
              更多工具&gt;扩展程序”，删除原来的安装插件
            </b>
          </p>
          <SectionOneScreenshot
            image={image[7]}
            number="Eight"
            width={280}
            height={180}
          />
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>第二步，在官网上点击 “下载安装包”</b>
          </p>
          <div className="PgCW-SectionOne__grayLine" />
          <p>
            <b>步驟3下载完成后请将安装包档案解压缩，详细方式请参考以下步骤：</b>
          </p>
          <p>1. 解压缩已下载的安装包</p>
          <SectionOneScreenshot
            image={image[8]}
            number="Nine"
            width={280}
            height={80}
          />
          <p>
            2. 打开谷歌浏览器，点击右上方菜单中的” 更多工具&gt;扩展程序” 将”
            开发者模式 ”打开
          </p>
          <SectionOneScreenshot
            image={image[9]}
            number="Ten"
            width={280}
            height={155}
          />
          <SectionOneScreenshot
            image={image[10]}
            number="Eleven"
            width={280}
            height={125}
          />
          <p>3. 点击” 加载已解压的扩展程序”</p>
          <SectionOneScreenshot
            image={image[11]}
            number="Twelve"
            width={280}
            height={136}
          />
          <p>4. 点击” 选择文件夹”</p>
          <SectionOneScreenshot
            image={image[12]}
            number="Thirteen"
            width={280}
            height={130}
          />
          <SectionOneScreenshot
            image={image[13]}
            number="Fourteen"
            width={280}
            height={130}
          />
          <p>6.在谷歌浏览器右上方将会出现已经安装好的插件</p>
          <SectionOneScreenshot
            image={image[14]}
            number="Fifteen"
            width={280}
            height={180}
          />
        </div>
      </div>
    </main>
  );
};

export default SectionOne;
