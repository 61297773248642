import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";

const SectionOneScreenshot = ({
  image,
  number,
  width,
  height,
  classes = "PgCW-SectionOne__img",
}) => {
  return (
    <picture className={getTrueClasses(`${classes}`, `${classes}${number}`)}>
      <source
        media="(min-width: 768px)"
        srcSet={`${image[0]} 1x, ${image[1]} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${image[2]} 1x, ${image[3]} 2x`}
      />
      <img
        src={`${image[0]}`}
        alt="image"
        className={getTrueClasses(`${classes}`, `${classes}${number}`)}
        width={`${width}`}
        height={`${height}`}
      />
    </picture>
  );
};

SectionOneScreenshot.propTypes = {
  image: PropTypes.string,
  number: PropTypes.string,
  classes: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SectionOneScreenshot;
